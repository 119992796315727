import { BrowserModule } from '@angular/platform-browser';
import { NgModule, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule, Router, NavigationEnd } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import * as jQuery from 'jquery';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faChevronRight, faChevronLeft, faCaretDown, faTimesCircle, faUserCircle, faShoppingBasket, faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import { faChevronLeft as falChevronLeft, faChevronRight as falChevronRight, faSearch, faChevronUp} from '@fortawesome/pro-light-svg-icons';

import { SiteWrapper } from './wrapper.component';
import { HeaderComponent } from './global/header/header.component';
import { FooterComponent } from './global/footer/footer.component';
import { HeaderService } from './global/header/header.service';
import { LoginpopupService } from './global/loginpopup/loginpopup.service';
import { FooterService } from './global/footer/footer.service';
import { HomepageComponent } from './homepage/homepage.component';
import { HowitworksComponent } from './howitworks/howitworks.component';
import { ViewphoneComponent } from './viewphone/viewphone.component';
import { CartComponent } from './cart/cart.component';
import { DetailsComponent } from './cart/details/details.component';
import { PaymentComponent } from './cart/payment/payment.component';
import { CompleteComponent } from './cart/complete/complete.component';
import { BankdetailsComponent } from './cart/bankdetails/bankdetails.component';
import { DeliveryComponent } from './cart/delivery/delivery.component';
import { LoginComponent } from './cart/login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginpopupComponent } from './global/loginpopup/loginpopup.component';
import { TradeinComponent } from './user/tradein/tradein.component';
import { FaqComponent } from './faq/faq.component';
import { ContactusComponent } from './contactus/contactus.component';
import { RequestComponent } from './user/passwordreset/request/request.component';
import { ConfirmComponent } from './user/passwordreset/confirm/confirm.component';
import { MobilesComponent } from './mobiles/mobiles.component';
import { PrivacypolicyComponent } from './privacypolicy/privacypolicy.component';
import { TabletsComponent } from './tablets/tablets.component';
import { TermscondtionsComponent } from './termscondtions/termscondtions.component';
import { ViewtabletComponent } from './viewtablet/viewtablet.component';
import { WearablesComponent } from './wearables/wearables.component';
import { ViewwearableComponent } from './viewwearable/viewwearable.component';
import { CookiepolicyComponent } from './cookiepolicy/cookiepolicy.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { IngrammicroService } from './ingrammicro.service';

const siteRoutes: Routes = [
  {path: '', component: HomepageComponent},
  {path: 'how-it-works', component: HowitworksComponent},
  {path: 'faqs', component: FaqComponent},
  {path: 'phones', component: MobilesComponent},
  {path: 'tablets', component: TabletsComponent},
  {path: 'wearables', component: WearablesComponent},
  {path: 'viewphone/:model/:id', component: ViewphoneComponent},
  {path: 'viewphone/:model/:id/details', component: ViewphoneComponent},
  {path: 'viewphone/:model/:id/imei', component: ViewphoneComponent},
  {path: 'viewphone/:model/:id/imei/details', component: ViewphoneComponent},
  {path: 'viewphone/:model/:id/imei/details', component: ViewphoneComponent},
  {path: 'viewphone/:id', component: ViewphoneComponent},
  {path: 'viewphone/:id/imei', component: ViewphoneComponent},
  {path: 'viewtablet/:model/:id', component: ViewtabletComponent},
  {path: 'viewtablet/:model/:id/imei', component: ViewtabletComponent},
  {path: 'viewtablet/:model/:id/imei/details', component: ViewtabletComponent},
  {path: 'viewtablet/:id', component: ViewtabletComponent},
  {path: 'viewtablet/:id/imei', component: ViewtabletComponent},
  {path: 'viewwearable/:model/:id', component: ViewwearableComponent},
  {path: 'viewwearable/:model/:id/imei', component: ViewwearableComponent},
  {path: 'viewwearable/:model/:id/imei/details', component: ViewwearableComponent},
  {path: 'viewwearable/:id', component: ViewwearableComponent},
  {path: 'viewwearable/:id/imei', component: ViewwearableComponent},
  {path: 'cart', component: CartComponent},
  {path: 'cart/details', component: DetailsComponent},
  {path: 'cart/bankdetails', component: BankdetailsComponent},
  {path: 'cart/payment', component: PaymentComponent},
  {path: 'cart/login', component: LoginComponent},
  {path: 'cart/complete', component: CompleteComponent},
  {path: 'cart/delivery', component: DeliveryComponent},
  {path: 'contact-us', component: ContactusComponent},
  {path: 'user/tradein', component: TradeinComponent},
  {path: 'user/passwordreset/confirm/:token', component: ConfirmComponent},
  {path: 'terms', component: TermscondtionsComponent},

  {path: '**', component: HomepageComponent}
];

@NgModule({
  declarations: [
	SiteWrapper,
	HeaderComponent,
	FooterComponent,
	HomepageComponent,
	HowitworksComponent,
	ViewphoneComponent,
	CartComponent,
	DetailsComponent,
	PaymentComponent,
	CompleteComponent,
	BankdetailsComponent,
	DeliveryComponent,
	LoginComponent,
	LoginpopupComponent,
	TradeinComponent,
  FaqComponent,
  ContactusComponent,
  RequestComponent,
  ConfirmComponent,
  MobilesComponent,
  PrivacypolicyComponent,
  TabletsComponent,
  TermscondtionsComponent,
  ViewtabletComponent,
  WearablesComponent,
  ViewwearableComponent,
  CookiepolicyComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(siteRoutes, {scrollPositionRestoration: 'enabled'}),
	  FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    NgxCaptchaModule
  ],
  providers: [HeaderService, FooterService, LoginpopupService],
  bootstrap: [SiteWrapper],

})
export class AppModule{
  constructor(library: FaIconLibrary, private router: Router, private ingrammicro: IngrammicroService ) {
  library.addIcons(falChevronLeft, falChevronRight, faSearch, faChevronUp, faCaretDown, faTimesCircle, faUserCircle, faShoppingBasket, faExclamationTriangle);

    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) {
        if(localStorage.getItem('user')) {
          var user = JSON.parse(localStorage.getItem('user'));
          if (user['UserID']) {
              setTimeout(()=>{
                localStorage.setItem('user', "");
                localStorage.removeItem('recaptcha');
              }, 60000 * 30);
          }
        }
      }
    });

    setInterval(()=> {
      //if expiry token was created 30 minutes ago, remove recaptcha token
      if(localStorage.getItem('recaptcha_expiry')){
        if(Number(localStorage.getItem('recaptcha_expiry')) < Date.now()) {
          localStorage.removeItem('recaptcha');
          localStorage.removeItem('recaptcha_expiry');
        }
      }

      // if user is not blocked via 429/403 and the user's token has expired, ensure page refreshes so new token grabbed
      if (!ingrammicro.getCookie('user_blocked')){
        if(!ingrammicro.getCookie('token')){
          console.warn('token expired, reloading');
          // setTimeout(()=>(
          //   window.location.reload()
          // ), 2500)
        }
      }
    },1000);
  }
}

